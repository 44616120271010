html, body, #root {
  /*height: 100%;*/
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.App {
  text-align: center;
  background-color: #282c34;
  width: 100%;
  height: 100%;
}

.login-form {
  max-width: 45%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input, .login-form button {
  width: 270px;
  display: block;
  margin: 7px;
}

.login-form .login-error { 
  color: #ef0000;
  margin: 5px;
}

.layout {
  height: 100%;
}

.layout .header-nav .user-icon {
  color: #fff;
}

.layout .ant-layout-footer {
  /* margin-top: 30px; */
  /* position: absolute; */
  /* left: auto; */
  /* right: auto; */
  /* position: fixed; */
  bottom: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.header-nav {
  width: 100%;
  color: #ffffff;
  font-weight: bold;
}

.ant-layout-header .currentUser {
  color: #ffffff;
  user-select: none;
  font-size: 16pt;
}


.ant-layout-header .currentUser:hover {
  opacity: 0.8;
}

.ant-layout-header .currentUser a {
 color: #fff;
}

.ant-layout-header, .header-nav {
  background-color: rgb(211, 102, 0);
}

.ant-layout-header .ant-menu-item {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-layout-header .ant-menu-item:hover {
  background-color: #ff8c00a1 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}

.ant-menu-item-selected {
  background-color: #ff8c00a1 !important;
}

td.ant-table-column-sort {
  background-color: unset !important;
}

.ant-table-thead th.ant-table-column-sort {
  background-color: #fafafa !important;
}


.footer-text {
  text-align: center;
  font-size: 10pt;
  color: #41454ba5;
}

.new-study-btn {
  float: right;
  margin-bottom: 10px;
}

.study-modal-actions button {
  margin: 0 5px;
}

.study-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-text {
  color: #e71d1d;
  height: 25px;
}


.study-modal-actions {
  float: right;
}

.study-user-role {
  text-transform:capitalize;
}

.study-view .all-user-studies {
  display: none;
}

.study-view #export-study-data {
  margin: 10px 0 25px;
}

.studies-table .study-info {
  line-height: 1.3;
}

.studies-table .study-name {
  color: #282c34;
  font-weight: 600;
  font-size: 12pt;
}

.studies-table .study-name:hover {
  text-decoration: underline;
}

.studies-table .study-num-particpants {
  font-size: 10pt;
  opacity: 0.87;
}

.ant-descriptions-header {
  margin: 0;
}

.study-title {
  margin: 0;
  display: inline;
}
.invite-modal-body {
  line-height: 2.5;
  margin-left: 10px;
}

.invite-modal-body *{
  display: inline-block;
}

.study-badge {
  padding: 2px 4px;
  font-size: 9pt;
  margin: 0 4px;
  font-weight: 500;
  border-radius: 11px;
  border: 1px solid transparent;
  transition: all 500ms;
  text-align: center;
}

.study-badge:hover {
  opacity: 0.8;
  cursor: pointer;
  border: 1px solid #767676;
  transition: all 500ms;
}
.study-badge a {
  color: #282c34;
}

.study-dropdown {
  float: right;
}

.all-user-studies .study-badge {
  margin-bottom: 2px;
  font-size: 8pt;
  padding: 0;
  max-width: 125px;
  text-overflow: ellipsis;
  min-width: 80px;
}

.study-participant-nav {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 15px;
  display: block;
}

.user-info .last-active {
  font-weight: 100;
  font-size: 10pt;
  opacity: 0.8;
  padding-left: 10px;    
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5714285714285714;
}

.user-info .user-schedule, .study-info .user-schedule  {
  display: flex;
}

.user-info .user-schedule .personalized {
  opacity: 0.8;
}

.user-info .user-schedule .edit-button, .study-info .user-schedule .edit-button {
  margin: 0;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 11px;
}


.user-info .user-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info .user-actions {    
  display: flex;
  justify-content: space-around;
  padding-top: 12px;
}

.survey-step {
  margin: 10px 0;
}

.survey-step .choice {
  display: block;
  margin-left: 16px;
}

.survey-step hr {
  margin: 10px 0;
}

.survey-step * {
  margin: 0;
}

.survey-step.text .result, .survey-step .choice {
  font-style: italic;
}

.survey-step.scale .slider {
  width: 45%;
  margin: 10px;
  margin-top: 26px;
}

.new-user-btn {
  float: right;
  margin: 0 8px;
}

.all-users .new-user-btn {
  margin-bottom: 20px;
}

.users-table {
  margin-bottom:90px;
}

.code-btn {
  margin: 15px 5px ;
}

.code-modal-btn {
  margin: 5px;
}

.config-info p {
  margin: 0;
}

.events-table-num {
  opacity: 0.7;
  font-size: 10pt;
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}

.edit-config-btn {
  margin-left: auto;
  margin-right: 10px;
}

.config-modal-description {
  opacity: 0.8;
  margin-bottom: 18px;
  line-height: 1.2;
}

.config-form-controls {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.config-form-controls .delete-config {
  background-color: #ff4d4f;
  color: #fff;
  margin-left: 10px;;
  transition: all 300ms;
  opacity: 1;
}

.config-form-controls .delete-config:hover {
  background-color: #fff;
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  opacity: 1;
}

.config-form-controls .other-buttons {
  display: flex;
}

.config-num {
  width: 56px;
}

.delete-template:hover {
  opacity: 0.7;
  cursor: pointer;
}

.survey-fallback .survey-data {
  line-break: anywhere;
  width: 90%;
  margin-left: 25px;
  margin-bottom: 25px;
}

.personalized-tag {
  opacity: 0.7;
  font-style: italic;
  font-size: 11pt;
}

.date-from {
  opacity: 0.7;
}

.participant-name {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 170px;
}

.overview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  max-width: 800px;
  margin: auto;
}

.overview-container .graph-container {
  width: 100%;
  position: relative;
}

.overview-container .graph-container svg.recharts-surface {
  overflow: visible;
}

.overview-container .graph-container .graph-hr {
  width: 90%;
  opacity: 0.7;
}

.overview-container .graph-container .graph-title {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
  justify-content: center;
  margin: 0;
  position: absolute;
  left: 95px;
  opacity: 0.8;
  font-weight: 400;
  font-size: 12pt;
  z-index: 1000;
}

.grid-plots {
  width: 100%;
}

.grid-plots .category-stats svg.recharts-surface {
  margin-left: 25px;
  overflow: visible;
}

.grid-plots .context-tooltip, .graph-container .context-tooltip {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  padding: 15px 10px ;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.context-tooltip .challenge-item, .context-tooltip .survey-item {
  margin: 2px 4px;
  padding: 0 5px;
  border-radius: 10px;
  color: #282c34dd;
}
.context-tooltip .survey-item {
 font-family: monospace;
 line-height: 0.9;
}

.context-tooltip .survey-item.missed {
  color: #e71d1dcf;
}

.context-tooltip  .label {
  padding-bottom: 4px;
  line-height: 1;
}

.context-tooltip .template-name {
  font-weight: 600;
  opacity: 0.8;
}

.context-tooltip .study-day {
  opacity: 0.7;
}

.survey-progress {
  width: 80%;
  padding: 30px 0;
}

.survey-progress .progress-header {
  display: flex;
  justify-content: space-between;
}

.sparkline::after {
  content: "";
  border-bottom: 1px #282c3487 solid;
  width: 95%;
  display: block;
  position: relative;
  bottom: 11px;
}

.grid-survey {
  display: flex;
}

.grid-survey .challenges {
  min-width: 50%;
}

.grid-survey .challenges .challenge {
  padding: 5px;
  border-radius: 10px;
  color: #f5f5f5;
  font-weight: 700;
  margin: 0 3px;
  
}

.grid-survey .context {
  line-height: 1.2;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  min-width: 50%;
}

.info-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#admin-tag {
  margin-left: 10px;
  vertical-align: text-bottom;
}

